import { FC, useCallback, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin, faPlugCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Dialog, DialogActionRef } from "./Dialog";
import { Headset } from "../hooks/service";
import { gql, useMutation } from "@apollo/client";
import {
  Wrapper,
  Status,
  Screen,
  ID,
  Battery,
  Temperature,
  Popover,
  Button2,
} from "./HeadsetItem";

export const HeadsetItem: FC<{
  width: number;
  headset: Headset;
  selected?: boolean;
  onClick: { (e: React.MouseEvent<HTMLDivElement, MouseEvent>): unknown };
}> = ({ width, headset: device, selected, onClick }) => {
  const [recenter] = useMutation(gql`
    mutation Recenter($id: ID!) {
      recenter(id: $id)
    }
  `);
  const dialogActionRef = useRef<DialogActionRef | null>(null);
  const handleRecenter = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();

      if (dialogActionRef.current) {
        dialogActionRef.current.open({
          onConfirm: (e) => {
            e.stopPropagation();
            e.preventDefault();

            recenter({
              variables: {
                id: device.id,
              },
            });
          },
          onDismiss: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
          message: (
            <>
              {device.id}번 헤드셋 화면을 정렬할까요?
              <br />
              <div style={{ fontSize: 14, marginTop: 5 }}>
                고객이 바른자세로 <b>정면을 바라보도록 안내</b>헤주세요.
              </div>
            </>
          ),
          confirmDelay: 1,
          confirmLabel: `${device.id}번 화면 정렬하기`,
          dismissLabel: "닫기",
        });
      }
    },
    [device]
  );

  return (
    <>
      <Dialog actionRef={(ref) => (dialogActionRef.current = ref)} />
      <Wrapper
        $headset={device}
        $selected={selected}
        onClick={onClick}
        style={{ minWidth: width, maxWidth: width }}
      >
        <Status $headest={device} />
        <Screen $headest={device}>
          <FontAwesomeIcon icon={faPlugCircleXmark} />
        </Screen>
        <ID $headest={device} />
        <Battery $headest={device} />
        <Temperature $headest={device} />

        <Popover
          $selected={selected}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Button2 onClick={handleRecenter}>
            <FontAwesomeIcon icon={faMapPin} />
            시선 교정
          </Button2>
          {/* <Button2 onClick={handleCenter}></Button2> */}
        </Popover>
      </Wrapper>
    </>
  );
};
