import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import React, { FC, ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "./index.css";
import { router } from "./pages";

let HTTP_URL = `https://20.0.0.252:9443/graphql`;
let WS_URL = `wss://20.0.0.252:9443/subscriptions`;

const LOCALHOST_HTTP_URL = "https://localhost:9191/graphql";
const LOCALHOST_WS_URL = "wss://localhost:9191/subscriptions";

if (window.location.hostname.includes("localhost")) {
  HTTP_URL = LOCALHOST_HTTP_URL;
  WS_URL = LOCALHOST_WS_URL;
} else {
  HTTP_URL = `https://${window.location.hostname}:9443/graphql`;
  WS_URL = `wss://${window.location.hostname}:9443/subscriptions`;
}

const httpLink = new HttpLink({
  uri: HTTP_URL,
});

const wsLink = new WebSocketLink({
  uri: WS_URL,
  options: {
    reconnect: true,
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Auth: FC<{ children: ReactNode }> = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const AUTH_KEY = "token";

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setAuth(localStorage.getItem(AUTH_KEY) !== null);
  //   }, 100);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  if (!auth) {
    return (
      <input
        autoFocus
        type="password"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: 300,
          height: 60,
          fontSize: 40,
          transform: "translate(-50%, -50%)",
        }}
        onChange={(e) => {
          if (e.target.value === "1025") {
            setAuth(true);
            // localStorage.setItem(AUTH_KEY, "yes");
          }
        }}
      />
    );
  }

  return <>{children}</>;
};

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Auth>
        <RouterProvider router={router} />
      </Auth>
    </ApolloProvider>
  </React.StrictMode>
);
