import { FC, useMemo } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";

import { Button } from "./Button";
import { Headset, HeadsetStatus } from "../hooks/service";

export const Wrapper = styled.div<{ $headset: Headset; $selected?: boolean }>`
  height: 50px;
  display: flex;
  padding: 2px;
  background: #242526;
  border-radius: 4px;
  margin: 5px 0;
  position: relative;
  transition: outline 200ms, opacity 200ms, transform 100ms, box-shadow 200ms;

  ${({ $selected }) => {
    if ($selected !== undefined) {
      if ($selected) {
        return `
          opacity: 1;
          transform: scale(1.1);
          z-index: 100;
          box-shadow: 0 0 10px #000;
        `;
      } else {
        return `
          opacity: 0.5;
          box-shadow: 0 0 2px #000;
        `;
      }
    }
  }}

  * {
    transition: all 200ms;
  }

  ${({ $headset }) => {
    if ($headset?.ok === false) {
      return "outline: 2px solid #ff0000";
    }

    return "outline: 1px solid #00000000";
  }}
`;

export const ID = styled.div<{ $headest: Headset }>`
  position: absolute;
  top: 3px;
  font-size: 12px;
  width: 85px;

  &::before {
    content: "${({ $headest }) => $headest.id} | ${({ $headest }) =>
      $headest.appVer}";
  }

  ${({ $headest }) => {
    if (!$headest.connection) {
      return "left: 25px;";
    } else {
      return "left: 15px;";
    }
  }}
`;

export const Battery: FC<{ $headest: Headset }> = ({ $headest }) => {
  const backgroundColor = useMemo(() => {
    if ($headest.battery > 70) return "#3AC95E";
    if ($headest.battery > 20) return "#FFCE12";
    return "#FF4136";
  }, [$headest.battery]);

  return (
    <div
      style={{
        position: "absolute",
        top: 5,
        right: 2,
        width: 20,
        height: 10,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 2,
          bottom: 0,
          borderRadius: 2,
          border: "1px solid #A7A8A8",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 1,
            left: 1,
            bottom: 1,
            width: `calc(${$headest.battery}% - 2px)`,
            borderRadius: 1,
            backgroundColor,
          }}
        />
      </div>

      <div
        style={{
          height: "33%",
          width: "5%",
          borderTopRightRadius: "100%",
          borderBottomRightRadius: "100%",
          backgroundColor: "#A7A8A8",
          position: "absolute",
          right: 0,
          top: "33%",
        }}
      />

      <Bolt $headest={$headest}>
        <FontAwesomeIcon
          style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}
          className="fg"
          icon={faBolt}
        />
        <FontAwesomeIcon
          style={{
            position: "absolute",
            top: 0,
            left: 1,
            transform: "scale(1)",
            zIndex: 0,
          }}
          className="bg"
          icon={faBolt}
        />
        <FontAwesomeIcon
          style={{
            position: "absolute",
            top: 0,
            left: -1,
            transform: "scale(1)",
            zIndex: 0,
          }}
          className="bg"
          icon={faBolt}
        />
      </Bolt>
    </div>
  );
};

export const Status = styled.div<{ $headest: Headset }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: all 200ms;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;

  ${({ $headest }) => {
    let color;
    let background;

    switch ($headest.status) {
      case HeadsetStatus.Offboarding:
      case HeadsetStatus.Offline:
        color = "#fff";
        background = "#000";
        break;
      case HeadsetStatus.Calibration:
        color = "#000";
        background = "#fff";
        break;
      case HeadsetStatus.Hub:
        color = "#000";
        background = "#fbaa07";
        break;
      case HeadsetStatus.Countdown:
        color = "#fff";
        background = "#000";
        break;
      case HeadsetStatus.Concert:
        color = "#fff";
        background = "#8338ec";
        break;
      case HeadsetStatus.Offboarding:
        color = "#fff";
        background = "#3a86ff";
        break;
    }

    return `
      color: ${color};

      &::before {
        content: "${$headest.status}";
        background-color: ${background};
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        z-index: 100;
        transition: all 400ms;
        align-items: flex-end;
        display: flex;
        justify-content: center;
        padding-bottom: 3px;
        box-sizing: border-box;
      }
    `;
  }};

  &::after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(0deg, #24252600 0%, #242526 100%);
    z-index: 100;
  }
`;

export const Temperature = styled.div<{ $headest: Headset }>`
  position: absolute;
  top: 4px;
  right: 25px;
  font-size: 9px;

  ${({ $headest }) => {
    if ($headest.temperature < 35) return "color: #00ff00;";
    if ($headest.temperature < 50) return "color: #ff8000;";
    return "color: #ff0000;";
  }}

  &::before {
    content: "${({ $headest }) => $headest.temperature.toFixed(0)}°C";
  }
`;

export const Screen = styled.div<{ $headest: Headset }>`
  position: absolute;
  top: 7px;
  left: 3px;
  width: 5px;
  height: 5px;
  border-radius: 50%;

  ${({ $headest }) => {
    if (!$headest.connection) {
      return `
        left: 5px;
        top: 4px;
        svg {
          fill: #444;
        }
      `;
    } else {
      return `
        svg {
          display: none;
        }
      `;
    }
  }}

  ${({ $headest }) => {
    if (!$headest.connection) return "";
    if ($headest.screen) return "background-color: #0f0;";
    return "background-color: #444;";
  }}
`;

const Bolt = styled.div<{ $headest: Headset }>`
  position: absolute;
  top: -1px;
  left: calc(50% - 6px);
  font-size: 12px;
  ${({ $headest }) =>
    $headest.charging
      ? "transform: scale(1); transform-origin: 5px 5px;"
      : "transform: scale(0); transform-origin: 5px 5px;"};

  .fg * {
    fill: ${({ $headest }) => {
      if ($headest.battery > 80) return "#fff";
      if ($headest.battery > 50) return "#FFCE12";
      return "#FF4136";
    }};
  }

  .bg * {
    fill: ${({ $headest }) => {
      if ($headest.battery > 80) return "#000";
      if ($headest.battery > 50) return "#000";
      return "#000";
    }};
  }
`;

export const Popover = styled.div<{ $selected?: boolean }>`
  position: absolute;
  bottom: -48px;
  left: 50%;
  transform-origin: top;
  transition: 400ms all;
  ${({ $selected }) =>
    $selected
      ? `
          opacity: 1;
          transform: translateX(-50%) scale(1);
        `
      : `
          pointer-events: none;
          opacity: 0;
          transform: translateX(-50%) translateY(10px) scale(0.9);
        `}
  // width: 117px;
  height: 40px;
  color: #000;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px #000;
  z-index: 100;
  box-sizing: border-box;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  outline: 3px solid #000;
  background: linear-gradient(0deg, #00000080 0%, #000 100%);

  &::before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid transparent;
    border-bottom: 5px solid #000;
    left: 50%;
    transform: translateX(-50%);
    top: -13px;
  }
`;

export const Button2 = styled(Button)`
  width: 50px;
  height: 30px;
  font-size: 11px;
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
