import { FC, ReactNode } from "react";
import styled from "styled-components";

export const InputGroup: FC<{
  children: ReactNode[];
  style?: React.CSSProperties;
}> = ({ children, style }) => {
  return <Wrapper style={style}>{children}</Wrapper>;
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  input {
    height: 30px;
  }

  button {
    height: 30px;
  }

  input + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 3px;
  }

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
