import { createBrowserRouter } from "react-router-dom";

import { AppDistribution } from "./AppDistribution";
import { DeviceManager } from "./DeviceManager";
import { FileManager } from "./FileManager";
import { Layout } from "./Layout";
import { Settings } from "./Settings";
import { TCW } from "./TCW";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <div>Oppose!</div>,
    children: [
      {
        path: "/",
        element: <TCW />,
      },
      {
        path: "/device-manager",
        element: <DeviceManager />,
      },
      {
        path: "/app-distribution",
        element: <AppDistribution />,
      },
      {
        path: "/file-manager",
        element: <FileManager />,
      },
      {
        path: "/settings/:tab",
        element: <Settings />,
      },
    ],
  },
]);
