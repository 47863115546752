import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMasksTheater } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { useSize } from "../hooks/size";

export const SideBar = () => {
  const location = useLocation();
  const [over, setOver] = useState(true);
  const [small, setSmall] = useState(true);
  const [width] = useSize();

  const handleEnter = useCallback(() => {
    if (width < 500) {
      return;
    }

    setOver(true);
    setSmall(false);
  }, [width]);

  const handleLeave = useCallback(() => {
    setOver(false);
  }, []);

  const handleClick = useCallback(() => {
    if (width > 500) {
      return;
    }

    setOver(false);
  }, [width]);

  useEffect(() => {
    if (small === false && over === false) {
      const timeout = setTimeout(() => {
        setSmall(true);
      }, 300);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [small, over]);

  return (
    <Wrapper $small={small}>
      <Body>
        <ItemWrapper
          onMouseEnter={handleEnter}
          onClick={handleClick}
          onMouseLeave={handleLeave}
          className={({ isActive }) => (isActive ? "active" : "")}
          to="/"
        >
          <Item>
            <FontAwesomeIcon
              icon={faMasksTheater}
              style={{ margin: "0 7px 0 0" }}
              className="icon"
            />
            <span>VR Concert</span>
          </Item>
        </ItemWrapper>

        <ItemWrapper
          onMouseEnter={handleEnter}
          onClick={handleClick}
          onMouseLeave={handleLeave}
          className={({ isActive }) => (isActive ? "active" : "")}
          to="/device-manager"
        >
          <Item>
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8422 14.4037C12.1904 14.9387 12.7564 15.3015 13.3903 15.3996H15.9C17.8882 15.3996 19.5 13.7878 19.5 11.7996V8.19961C19.5 6.21138 17.8882 4.59961 15.9 4.59961H5.1C3.11177 4.59961 1.5 6.21138 1.5 8.19961V11.7996C1.5 13.7878 3.11177 15.3996 5.1 15.3996H7.61701C8.23463 15.304 8.78792 14.9571 9.13794 14.4444L9.25978 14.1625C9.55561 13.4782 10.3502 13.1632 11.0346 13.459C11.3504 13.5955 11.6022 13.8474 11.7385 14.1633L11.8422 14.4037ZM17.7 11.7996C17.7 12.7937 16.8941 13.5996 15.9 13.5996H13.5813C13.5163 13.5768 13.4575 13.5402 13.41 13.4937L13.3912 13.4501C13.0731 12.7131 12.4857 12.1253 11.7488 11.8068C10.1519 11.1165 8.2978 11.8515 7.60753 13.4484L7.58157 13.5084C7.53701 13.548 7.48393 13.5792 7.42602 13.5996H5.1C4.10589 13.5996 3.3 12.7937 3.3 11.7996V8.19961C3.3 7.2055 4.10589 6.39961 5.1 6.39961H15.9C16.8941 6.39961 17.7 7.2055 17.7 8.19961V11.7996Z"
                fill="white"
              />
            </svg>
            <span>Device Manager</span>
          </Item>
        </ItemWrapper>

        {/* <ItemWrapper
          onMouseEnter={handleEnter}
          onClick={handleClick}
          onMouseLeave={handleLeave}
          className={({ isActive }) => (isActive ? "active" : "")}
          to="/file-manager"
        >
          <Item>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.33333 2H3.77778C2.79594 2 2 2.79594 2 3.77778V7.33333C2 8.31517 2.79594 9.11111 3.77778 9.11111H7.33333C8.31517 9.11111 9.11111 8.31517 9.11111 7.33333V3.77778C9.11111 2.79594 8.31517 2 7.33333 2ZM16.2224 2H12.6668C11.685 2 10.8891 2.79594 10.8891 3.77778V7.33333C10.8891 8.31517 11.685 9.11111 12.6668 9.11111H16.2224C17.2042 9.11111 18.0002 8.31517 18.0002 7.33333V3.77778C18.0002 2.79594 17.2042 2 16.2224 2ZM3.77778 10.8889H7.33333C8.31517 10.8889 9.11111 11.6848 9.11111 12.6666V16.2222C9.11111 17.204 8.31517 18 7.33333 18H3.77778C2.79594 18 2 17.204 2 16.2222V12.6666C2 11.6848 2.79594 10.8889 3.77778 10.8889ZM16.2224 10.8889H12.6668C11.685 10.8889 10.8891 11.6848 10.8891 12.6666V16.2222C10.8891 17.204 11.685 18 12.6668 18H16.2224C17.2042 18 18.0002 17.204 18.0002 16.2222V12.6666C18.0002 11.6848 17.2042 10.8889 16.2224 10.8889Z"
                fill="white"
              />
            </svg>
            <span>File Manager</span>
          </Item>
        </ItemWrapper>

        <ItemWrapper
          onMouseEnter={handleEnter}
          onClick={handleClick}
          onMouseLeave={handleLeave}
          className={({ isActive }) => (isActive ? "active" : "")}
          to="/app-distribution"
        >
          <Item>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.89901 2.52416L9.38056 3.98998L16.6088 3.98988C17.5617 3.98988 18.261 4.73492 18.261 5.70705V7.42423H1.73926V3.98988C1.73926 3.02397 2.43869 2.27271 3.39143 2.27271H7.31486C7.53398 2.27271 7.74402 2.36318 7.89901 2.52416ZM1.73926 9.1414V16.0101C1.73926 17.0338 2.42684 17.7273 3.39143 17.7273H16.6088C17.5683 17.7273 18.261 16.9766 18.261 16.0101V9.1414H1.73926Z"
                fill="white"
              />
            </svg>
            <span>App Distribution</span>
          </Item>
        </ItemWrapper> */}

        <ItemWrapper
          onMouseEnter={handleEnter}
          onClick={handleClick}
          onMouseLeave={handleLeave}
          className={({ isActive }) => (isActive ? "active" : "")}
          to="/settings/theater"
          $active={location.pathname.startsWith("/settings")}
        >
          <Item>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.9165 3.22802V2.50002C7.9165 2.03978 8.2896 1.66669 8.74984 1.66669H11.2498C11.7101 1.66669 12.0832 2.03978 12.0832 2.50002V3.22802C12.5126 3.35996 12.9246 3.53161 13.3149 3.7387L13.83 3.22358C14.1554 2.89814 14.6831 2.89814 15.0085 3.22358L16.7763 4.99135C17.1017 5.31678 17.1017 5.84442 16.7763 6.16986L16.2612 6.68498C16.4682 7.0753 16.6399 7.4873 16.7718 7.91669H17.4998C17.9601 7.91669 18.3332 8.28978 18.3332 8.75002V11.25C18.3332 11.7103 17.9601 12.0834 17.4998 12.0834H16.7718C16.6399 12.5127 16.4682 12.9247 16.2612 13.3151L16.7763 13.8302C17.1017 14.1556 17.1017 14.6833 16.7763 15.0087L15.0085 16.7765C14.6831 17.1019 14.1554 17.1019 13.83 16.7765L13.3149 16.2613C12.9246 16.4684 12.5126 16.6401 12.0832 16.772V17.5C12.0832 17.9603 11.7101 18.3334 11.2498 18.3334H8.74984C8.2896 18.3334 7.9165 17.9603 7.9165 17.5V16.772C7.48712 16.6401 7.07512 16.4684 6.68479 16.2613L6.16968 16.7765C5.84424 17.1019 5.3166 17.1019 4.99116 16.7765L3.2234 15.0087C2.89796 14.6833 2.89796 14.1556 3.2234 13.8302L3.73852 13.3151C3.53143 12.9247 3.35977 12.5127 3.22784 12.0834H2.49984C2.0396 12.0834 1.6665 11.7103 1.6665 11.25V8.75002C1.6665 8.28978 2.0396 7.91669 2.49984 7.91669H3.22784C3.35977 7.4873 3.53143 7.0753 3.73852 6.68498L3.2234 6.16986C2.89796 5.84442 2.89796 5.31678 3.2234 4.99135L4.99116 3.22358C5.3166 2.89814 5.84424 2.89814 6.16968 3.22358L6.68479 3.7387C7.07512 3.53161 7.48712 3.35996 7.9165 3.22802ZM14.9998 10C14.9998 7.23846 12.7614 5.00002 9.99984 5.00002C7.23827 5.00002 4.99984 7.23846 4.99984 10C4.99984 12.7616 7.23827 15 9.99984 15C12.7614 15 14.9998 12.7616 14.9998 10ZM11.6665 10C11.6665 9.0795 10.9204 8.33335 9.99984 8.33335C9.07932 8.33335 8.33317 9.0795 8.33317 10C8.33317 10.9205 9.07932 11.6667 9.99984 11.6667C10.9204 11.6667 11.6665 10.9205 11.6665 10Z"
                fill="white"
              />
            </svg>
            <span>Settings</span>
          </Item>
        </ItemWrapper>
      </Body>
    </Wrapper>
  );
};

const Body = styled.div`
  width: 200px;
  height: 100%;
`;

const ItemWrapper = styled(NavLink)<{ $active?: boolean }>`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 8px;
  text-decoration: none;
  background-color: #18191a;

  path {
    fill: #a7a8a8;
  }

  &:hover {
    div {
      background-color: #303031;
      color: #e4e6eb;
    }

    path {
      fill: #e4e6eb;
    }
  }

  &.active {
    div {
      background-color: #212f46;
      color: #4477de;

      path {
        fill: #4477de;
      }
    }
  }

  ${({ $active }) =>
    $active
      ? `
          cursor: default;

          div {
            background-color: #212f46;
            color: #4477de;

            path {
              fill: #4477de;
            }
          }
        `
      : ""}
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  padding-left: 15px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: 10px center;

  svg {
    margin-right: 5px;
  }
`;

const Wrapper = styled.div<{ $small?: boolean }>`
  position: fixed;
  z-index: 200;
  min-height: 100vh;
  background-color: #18191a;

  flex-basis: 200px;
  padding-top: 4px;
  overflow: hidden;
  transition: 200ms all;
  max-width: 200px;
  min-width: 40px;
  border-right: 1px solid #242526;

  svg {
    min-width: 20px;
  }

  * {
    transition: 200ms all;
  }

  ${({ $small }) =>
    $small &&
    `
      max-width: 40px;
      border-right: 1px solid #24252600;

      ${Item} {
        padding-left: 5px;
      }

      ${ItemWrapper} {
        padding: 0 5px;
        max-width: 40px;

        span {
          display: none;
        }

        .icon {
          // margin-right: 20px !important;
        }
      }
    `}
`;
