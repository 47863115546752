import { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

import { SideBar } from "../components/SideBar";
import { Body } from "../components/Body";

export const Layout = () => {
  const location = useLocation();

  const title = useMemo(() => {
    switch (location.pathname) {
      case "/":
        return "VR Concert";
      case "/device-manager":
        return "Device Manager";
      case "/app-distribution":
        return "App Distribution";
      case "/file-manager":
        return "File Manager";
      case "/settings/theater":
      case "/settings/app":
      case "/settings/audio":
      case "/settings/screen":
      case "/settings/tcw":
        return "Settings";
      default:
        return "AmazeVR";
    }
  }, [location.pathname]);

  return (
    <>
      <h1 style={{ margin: "10px 0 0 40px", lineHeight: 1.2 }}>{title}</h1>
      <SideBar />
      <Wrapper>
        <div style={{ minWidth: 40 }} />
        <Body>
          <Outlet />
        </Body>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  min-height: calc(100vh - 50px);
  display: flex;
`;
