import { useMutation, gql } from "@apollo/client";
import moment, { Moment } from "moment-timezone";
import styled from "styled-components";

import { HeadsetItem } from "../../components/HeadsetItem.1";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "../../components/Button";
import { useSize } from "../../hooks/size";
import { Dialog, DialogActionRef } from "../../components/Dialog";
import { Now } from "../../components/Now";
import {
  ConcertStatus,
  Headset,
  HeadsetStatus,
  useService,
} from "../../hooks/service";

export const TCW = () => {
  const [startedAt, setStartedAt] = useState<Moment | undefined>();
  const [sortBy, setSortBy] = useState<"calibrationStartAt" | "id" | "health">(
    "id"
  );
  const dialogActionRef = useRef<DialogActionRef | null>(null);

  const { auditorium, headsets } = useService();

  const [color, setColor] = useState("");

  useEffect(() => {
    if (!auditorium?.status) {
      return;
    }

    const colors: any = {
      [ConcertStatus.Enter]: "#888",
      [ConcertStatus.Onboarding]: "#fb5607",
      [ConcertStatus.Concert]: "#8338ec",
      [ConcertStatus.Postshow]: "#8338ec",
      [ConcertStatus.Standby]: "#000000",
    };

    setColor(colors[auditorium.status]);
  }, [auditorium?.status]);

  const [setStatus] = useMutation(gql`
    mutation ChangeStatus($status: ConcertStatus!) {
      setStatus(status: $status)
    }
  `);

  const changeStatus = useCallback((status: ConcertStatus) => {
    if (dialogActionRef.current) {
      switch (status) {
        case ConcertStatus.Enter:
        case ConcertStatus.Onboarding:
          setStatus({ variables: { status } });
          break;
        case ConcertStatus.Concert:
          dialogActionRef.current.open({
            onConfirm: () => {
              setStatus({ variables: { status } });
              setStartedAt(moment());
            },
            onDismiss: () => {},
            message: "정말로 공연을 시작할까요?",
            confirmDelay: 3,
            confirmLabel: "공연 시작하기",
            dismissLabel: "닫기",
          });
          break;
        case ConcertStatus.Postshow:
          dialogActionRef.current.open({
            onConfirm: () => {
              setStatus({ variables: { status } });
              setStartedAt(moment());
            },
            onDismiss: () => {},
            message: "정말로 포스트쇼를 시작할까요?",
            confirmDelay: 3,
            confirmLabel: "포스트쇼 시작하기",
            dismissLabel: "닫기",
          });
          break;
        case ConcertStatus.Standby:
          dialogActionRef.current.open({
            onConfirm: () => {
              setStatus({ variables: { status } });
            },
            onDismiss: () => {},
            message: "정말로 공연을 종료할까요?",
            confirmDelay: 3,
            confirmLabel: "공연 종료하기",
            dismissLabel: "닫기",
          });
          break;
      }
    }
  }, []);

  const [selectedId, setSelectedId] = useState<undefined | number>();

  const size = useSize();
  const { cellWidth } = useMemo(() => {
    let w = size[0] - 40 - 5 - 5;
    const rowCount = Math.max(3, Math.floor(w / 160));
    w -= rowCount * 5;
    const cellWidth = w / rowCount - 5;

    return { cellWidth };
  }, [size]);

  const { syncing, mounted, charging } = useMemo(() => {
    const list = Object.values(headsets || {});
    let targetStatuses: HeadsetStatus[] = [];

    switch (auditorium?.status) {
      case ConcertStatus.Enter:
        targetStatuses = [HeadsetStatus.Standby];
        break;
      case ConcertStatus.Onboarding:
        targetStatuses = [HeadsetStatus.Calibration, HeadsetStatus.Hub];
        break;
      case ConcertStatus.Concert:
        targetStatuses = [
          HeadsetStatus.Countdown,
          HeadsetStatus.Concert,
          HeadsetStatus.Offboarding,
        ];
        break;
      case ConcertStatus.Postshow:
        targetStatuses = [HeadsetStatus.Standby];
        break;
      default:
        targetStatuses = [HeadsetStatus.Offline, HeadsetStatus.Standby];
        break;
    }

    const mounted =
      auditorium.status === ConcertStatus.Standby
        ? list
        : list.filter(
            (i) =>
              ![HeadsetStatus.Offline, HeadsetStatus.Standby].includes(i.status)
          );
    const unmounted = list.filter((i) => [
      HeadsetStatus.Offline,
      HeadsetStatus.Standby,
    ]);

    return {
      syncing: mounted.filter((i) => !targetStatuses.includes(i.status)).length,
      mounted: mounted.length,
      charging: unmounted.filter((i) => i.charging).length,
    };
  }, [auditorium?.status, headsets]);

  const list = useMemo(() => {
    const _ = Object.values(headsets || {});

    if (sortBy === "calibrationStartAt") {
      // 헤드셋 착용순
    } else if (sortBy === "id") {
      // 기기 이름순
      return _.sort((a, b) => a.id - b.id);
    } else {
      // 충전 상태순
      return _.sort((a, b) => b.battery - a.battery);
    }

    return _;
  }, [headsets, sortBy]);

  return (
    <div
      style={{
        width: "100%",
        background: `${color}30`,
        transition: "200ms all",
      }}
    >
      <Dialog actionRef={(ref) => (dialogActionRef.current = ref)} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 10,
        }}
      >
        <AuditoriumName>{auditorium?.name}</AuditoriumName>
        <Now />
        <AuditoriumName />
      </div>
      <b style={{ padding: "10px 20px 0 20px", display: "block" }}>정렬</b>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          margin: "10px 0",
        }}
      >
        <Button
          // disabled={"calibrationStartAt" === sortBy}
          disabled
          onClick={() => setSortBy("calibrationStartAt")}
          style={{ flexBasis: "30%", height: 40 }}
        >
          헤드셋 착용순 (사용불가)
        </Button>
        <Button
          disabled={"id" === sortBy}
          onClick={() => setSortBy("id")}
          style={{ flexBasis: "30%", height: 40 }}
        >
          기기 이름순
        </Button>
        <Button
          disabled={"health" === sortBy}
          onClick={() => setSortBy("health")}
          style={{ flexBasis: "30%", height: 40 }}
        >
          충전 상태순
        </Button>
      </div>
      <b style={{ padding: "10px 20px 0 20px", display: "block" }}>
        콘서트 컨트롤
      </b>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          margin: "10px 0",
        }}
      >
        <Button
          disabled={ConcertStatus.Standby !== auditorium?.status}
          $selected={ConcertStatus.Enter === auditorium?.status}
          onClick={() => changeStatus(ConcertStatus.Enter)}
          style={{
            flexBasis: "23%",
            padding: 0,
            whiteSpace: "nowrap",
            height: 65,
            fontSize: 20,
          }}
        >
          입장시작
          <div style={{ fontSize: 14 }}>
            {/* {concert.calibrationStartedAt?.format("HH:mm:ss")} */}
          </div>
        </Button>
        <Button
          disabled={ConcertStatus.Enter !== auditorium?.status}
          $selected={ConcertStatus.Onboarding === auditorium?.status}
          onClick={() => changeStatus(ConcertStatus.Onboarding)}
          style={{
            flexBasis: "23%",
            padding: 0,
            whiteSpace: "nowrap",
            height: 65,
            fontSize: 20,
          }}
        >
          경험시작
          <div style={{ fontSize: 14 }}>
            {/* {concert.calibrationStartedAt?.format("HH:mm:ss")} */}
          </div>
        </Button>
        <Button
          disabled={ConcertStatus.Onboarding !== auditorium?.status}
          $selected={ConcertStatus.Concert === auditorium?.status}
          onClick={() => changeStatus(ConcertStatus.Concert)}
          style={{
            flexBasis: "23%",
            padding: 0,
            whiteSpace: "nowrap",
            height: 65,
            fontSize: 20,
          }}
        >
          콘서트 시작
          <br />
          <div style={{ fontSize: 14 }}>
            {/* {concert.calibrationStartedAt?.format("HH:mm:ss")} */}
          </div>
        </Button>
        <Button
          disabled={
            !auditorium?.status ||
            ![ConcertStatus.Concert].includes(auditorium?.status)
          }
          $selected={ConcertStatus.Postshow === auditorium?.status}
          onClick={() => changeStatus(ConcertStatus.Postshow)}
          style={{
            flexBasis: "23%",
            padding: 0,
            whiteSpace: "nowrap",
            height: 65,
            fontSize: 20,
          }}
        >
          포스트쇼
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Stat $label="동기화중">{syncing}</Stat>
        <Stat $label="착용중">{mounted}</Stat>
        <Stat $label="충전중">{charging}</Stat>
        <Button
          disabled={!auditorium?.status}
          $selected={ConcertStatus.Standby === auditorium?.status}
          onClick={() => changeStatus(ConcertStatus.Standby)}
          style={{
            flexBasis: "10%",
            padding: 0,
            whiteSpace: "nowrap",
            height: 30,
            fontSize: 17,
            marginRight: 10,
          }}
        >
          종료
        </Button>
      </div>

      <b style={{ padding: "10px 20px 0 20px", display: "block" }}>
        전체 헤드셋
      </b>
      <Wrapper>
        {list.map((headset, i) => {
          return (
            <HeadsetItem
              key={headset.id}
              headset={headset}
              width={cellWidth}
              selected={
                selectedId === undefined ? undefined : headset.id === selectedId
              }
              onClick={(e) => {
                if (selectedId) {
                  setSelectedId(undefined);
                } else {
                  setSelectedId(headset.id);
                }
              }}
            />
          );
        })}
        <div style={{ minWidth: cellWidth, maxWidth: cellWidth }} />
        <div style={{ minWidth: cellWidth, maxWidth: cellWidth }} />
        <div style={{ minWidth: cellWidth, maxWidth: cellWidth }} />
        <div style={{ minWidth: cellWidth, maxWidth: cellWidth }} />
        <div style={{ minWidth: cellWidth, maxWidth: cellWidth }} />
        <div style={{ minWidth: cellWidth, maxWidth: cellWidth }} />
        <div style={{ minWidth: cellWidth, maxWidth: cellWidth }} />
        <div style={{ minWidth: cellWidth, maxWidth: cellWidth }} />
        <div style={{ minWidth: cellWidth, maxWidth: cellWidth }} />
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px;
`;

const AuditoriumName = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: #44bb00;
`;

const Stat = styled.div<{ $label: string }>`
  flex: 1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: bold;
  position: relative;
  flex-direction: column;

  &:after {
    content: "${({ $label }) => $label}";
    font-weight: normal;
  }
`;
