import styled from "styled-components";

export const Input = styled.input`
  height: 30px;
  background-color: #3a3b3c;
  border: none;
  padding-left: 10px;
  outline: 1px solid #18191a00;
  cursor: text;
  border-radius: 4px;
  transition: all 200ms;
  outline: 1px solid #3a3b3c;
  font-size: 16px;

  &:focus {
    outline: 1px solid #7a7a7b;
    background-color: #2a2b2c;
    transition: none;
  }

  ::placeholder {
    color: #757575;
  }
`;
