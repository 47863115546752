import {
  faBatteryThreeQuarters,
  faBolt,
  faCloudArrowDown,
  faCodeCommit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import moment from "moment";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { DeviceEvent } from "../models/device_event";
import { Button } from "../components/Button";

type SimpleMDMDevice = {
  id: number; // ex) 0
  ssid: string;
  mdmVersion: number; // ex) 1
  ktheaterVersion: number; // ex) 1
  batteryTemperature: number; // 배터리 온도 (C)
  batteryCharging: boolean; // 배터리 충전 여부
  batteryChargeCounter: number; // 누적 충전량 (uAh)
  batteryCurrentNow: number; // 베터리 변화속도 (uA)
  batteryCapacity: number; // 현재 배터리 잔량 (%, 0 ~ 100)
  lastMessageAt: number; // 마지막 서버와 통신한 시간
  bootAt: number; // 장비 켜진 시간
  shutdownAt: number; // 장비 꺼진 시간
};

type Column = {
  key: string;
  width?: number;
  render?: { (device: SimpleMDMDevice): ReactNode };
  label: ReactNode;
  children?: Column[];
  fixed?: "left" | "right";
};

export const MDM_ENDPOINT = "http://34.208.148.13:23334/mdm-devices";

const useSimpleMDMDevices = () => {
  const [devices, setDevices] = useState<SimpleMDMDevice[]>([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await Axios.get<SimpleMDMDevice[]>(MDM_ENDPOINT);
      setDevices(data);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetch();

    const interval = setInterval(() => {
      fetch();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [fetch]);

  return { SimpleMDMDevices: devices, loading, error };
};

export const DeviceManager = () => {
  const { SimpleMDMDevices, loading, error } = useSimpleMDMDevices();

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now());
    }, 16);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const columns: Column[] = useMemo(() => {
    return [
      {
        width: 50,
        key: "ID",
        label: "ID",
        fixed: "left",
        render: (device: SimpleMDMDevice) => (
          <>
            <div
              style={{
                backgroundColor:
                  Date.now() - device.lastMessageAt! > 90 * 1_000
                    ? "red"
                    : `hsl(${Math.min(
                        180 +
                          Math.floor(
                            (Date.now() - device.lastMessageAt!) / 1000
                          ),
                        255
                      )}, 100%, 50%)`,
                width: 5,
                height: 5,
                borderRadius: "100%",
                marginRight: 5,
              }}
            />
            {device.id}
          </>
        ),
      },
      {
        width: 125,
        key: "ssid",
        label: "SSID",
        render: (device: SimpleMDMDevice) => (
          <>{device.ssid.replace(/"/g, "")}</>
        ),
      },
      {
        width: 60,
        key: "Mdm version",
        label: "MDM",
        render: (device: SimpleMDMDevice) => (
          <>
            <FontAwesomeIcon icon={faCodeCommit} style={{ marginRight: 5 }} />
            {device.mdmVersion}
          </>
        ),
      },
      {
        width: 120,
        key: "K Theater",
        label: "K Theater",
        render: (device: SimpleMDMDevice) => {
          return (
            <>
              <FontAwesomeIcon icon={faCodeCommit} style={{ marginRight: 5 }} />{" "}
              {device.ktheaterVersion}
            </>
          );
        },
      },
      {
        width: 35,
        key: "🌡️",
        label: "🌡️",
        render: (device: SimpleMDMDevice) => (
          <>{device.batteryTemperature?.toFixed(1)}</>
        ),
      },
      {
        key: "Battery",
        label: "Battery",
        children: [
          {
            width: 50,
            key: "Capacity",
            label: <FontAwesomeIcon icon={faBatteryThreeQuarters} />,
            render: (device: SimpleMDMDevice) => (
              <>
                {device.batteryCapacity}%
                {!!device.batteryCharging && (
                  <FontAwesomeIcon
                    icon={faBolt}
                    style={{ marginLeft: 2 }}
                    color="yellow"
                  />
                )}
              </>
            ),
          },
          {
            width: 145,
            key: "Charge Counter",
            label: "Battery (Current now)",
            render: (device: SimpleMDMDevice) => {
              if (!device.batteryChargeCounter) {
                return "-";
              }

              const remain = device.batteryChargeCounter / 1000;
              return (
                <>
                  {remain.toLocaleString(undefined, {
                    minimumFractionDigits: 3,
                    maximumFractionDigits: 3,
                  })}{" "}
                  mAh (
                  {device.batteryCurrentNow ? (
                    <>{(device.batteryCurrentNow / 1000 / 1000).toFixed(3)} A</>
                  ) : (
                    "-"
                  )}
                  )
                </>
              );
            },
          },
          // {
          //   width: 100,
          //   key: "Energy counter",
          //   label: "Energy counter",
          //   render: (device: SimpleMDMDevice) => (
          //     <>{device.batteryEnergyCounter}</>
          //   ),
          // },
        ],
      },
      {
        width: 70,
        key: "SSID",
        label: "SSID",
        render: (device: SimpleMDMDevice) => <>{/* {device.ssid} */}</>,
      },
      {
        width: 125,
        key: "Last message at",
        label: "Last message",
        render: (device: SimpleMDMDevice) => (
          <>
            {device.lastMessageAt
              ? moment(device.lastMessageAt).format("MM/DD/YYYY HH:mm:ss")
              : "-"}
          </>
        ),
      },
      {
        width: 125,
        key: "Boot at",
        label: "Boot",
        render: (device: SimpleMDMDevice) => (
          <>
            {device.bootAt
              ? moment(device.bootAt).format("MM/DD/YYYY HH:mm:ss")
              : "-"}
          </>
        ),
      },
      {
        width: 125,
        key: "Shutdown at",
        label: "Shutdown",
        render: (device: SimpleMDMDevice) => (
          <>
            {device.shutdownAt
              ? moment(device.shutdownAt).format("MM/DD/YYYY HH:mm:ss")
              : "-"}
          </>
        ),
      },
    ];
  }, [time]);

  const flattenColumns = useMemo(
    () => columns.map((i) => (i.children ? i.children : [i])).flat(),
    [columns]
  );

  const width = useMemo(
    () => flattenColumns.reduce((acc, column) => acc + (column.width || 0), 0),
    [flattenColumns]
  );

  return (
    <div>
      {error && (
        <div
          style={{
            position: "fixed",
            left: 0,
            bottom: 0,
            right: 0,
            top: 0,
            color: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none",
            fontSize: 20,
            textAlign: "center",
          }}
        >
          Cannot load devices.
          <br />
          <div style={{ fontSize: 15, marginTop: 10 }}>{error}</div>
        </div>
      )}
      <Body>
        <Table>
          <Thead style={{ width }}>
            <Tr style={{ width }}>
              {flattenColumns.map((column) => {
                return (
                  <Th
                    style={{
                      minWidth: column.width,
                      maxWidth: column.width,
                      position: column.fixed ? "sticky" : undefined,
                      left: column.fixed === "left" ? 0 : undefined,
                      right: column.fixed === "right" ? 0 : undefined,
                      backgroundColor: column.fixed ? "#18191A" : undefined,
                    }}
                    key={column.key}
                  >
                    {column.label}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody style={{ width }}>
            {SimpleMDMDevices?.map((device) => {
              return (
                <Tr key={device.id} style={{ width }}>
                  {flattenColumns.map((column) => {
                    return (
                      <Th
                        style={{
                          minWidth: column.width,
                          maxWidth: column.width,
                          position: column.fixed ? "sticky" : undefined,
                          left: column.fixed === "left" ? 0 : undefined,
                          right: column.fixed === "right" ? 0 : undefined,
                          backgroundColor: column.fixed ? "#18191A" : undefined,
                          alignItems: "center",
                          display: "flex",
                        }}
                        key={column.key}
                      >
                        {column.render?.(device)}
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Body>
    </div>
  );
};

const Table = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: column;

  div > div {
    padding: 5px;
  }
`;

const Thead = styled.div`
  border-bottom: 1px solid #444444;
  display: flex;
  flex-direction: column;
`;

const Tr = styled.div`
  display: flex;
  flex: 1;
`;

const Th = styled.div`
  display: flex;
`;

const Tbody = styled.div`
  display: flex;
  flex-direction: column;
`;

const Td = styled.div`
  display: flex;
  flex: 1;
`;

const Body = styled.div`
  overflow-x: auto;
  width: calc(100vw - 50px);
  height: 100%;
`;

const seconds = (s: number | null) => {
  if (s === null) {
    return "-";
  }

  if (s < 60) {
    return `${s.toFixed(0)} s`;
  }

  const m = s / 60;
  if (m < 60) {
    return `${m.toFixed(0)} m`;
  }

  const h = m / 60;
  return `${h.toFixed(1)} h`;
};
