import moment from "moment";
import { useEffect, useState } from "react";
import styled from "styled-components";

export const Now = () => {
  const [now, setNow] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment());
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <Wrapper>{now.format("HH:mm:ss SS")}</Wrapper>;
};

const Wrapper = styled.div`
  position: sticky;
  font-family: "Cousine", sans-serif;
  width: 200px;
  display: flex;
  justify-content: center;
  font-size: 25px;
  background: #00000090;
  color: #44bb00;
  z-index: 150;
  top: 0;
  border-radius: 10px;
  padding: 5px 0;
  align-items: center;
`;
