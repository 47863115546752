import styled from "styled-components";
import { NavLink, To, useNavigate } from "react-router-dom";
import { FC, ReactNode } from "react";

export const Tabs: FC<{
  tabs: ReactNode | ReactNode[];
  children: ReactNode | ReactNode[];
}> = ({ tabs, children }) => {
  return (
    <Wrapper>
      <ItemWrapper>
        {tabs}
        <Spacer />
      </ItemWrapper>
      <Body>{children}</Body>
    </Wrapper>
  );
};

export const TabItem: FC<{ to: To; children: any }> = ({ to, children }) => {
  const navigate = useNavigate();

  return (
    <Item to={to} className={({ isActive }) => (isActive ? "active" : "")}>
      {children}
    </Item>
  );
};

const Wrapper = styled.div`
  width: 100%;
  background-color: #242526;
  border-radius: 8px;
  overflow: hidden;
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
`;

const ItemWrapper = styled.div`
  display: flex;
  height: 50px;
  border-bottom: 1px solid #18191a;
`;

const Item = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 15px;
  font-weight: bold;
  padding: 0 20px;
  color: #a7a8a8;
  text-decoration: none;
  position: relative;
  transition: all 200ms;

  @media (max-width: 768px) {
    font-size: 12px;
    padding: 0 8px;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #4477de00;
    transition: all 200ms;
  }

  &:hover {
    background-color: #303031;
    color: #e4e6eb;
  }

  &.active {
    color: #4477de;
    cursor: default;
    background-color: transparent;

    &::after {
      background-color: #4477de;
    }
  }
`;

const Spacer = styled.div`
  flex: 1;
`;

export const TabBodyItem = styled.div`
  width: 100%;
  padding: 15px 20px;

  & > * {
    display: flex;
    align-items: center;
  }

  & + div {
    border-top: 1px solid #18191a;
  }

  h1 {
    font-size: 15px;
    line-height: 1;
    color: #e4e6eb;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }

  div.description {
    line-height: 1;

    @media (max-width: 768px) {
      font-size: 10px;
    }
  }
`;
