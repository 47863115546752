import { gql, useQuery, useSubscription } from '@apollo/client';
import { groupBy } from 'lodash';
import { useEffect, useMemo, useState } from "react";

export const useService = () => {
  const { data: {
    headsets: _cachedHeadsets,
    auditorium: _cachedAuditorium,
    audioDelay: _audioDelay,
  } = {}, refetch } = useQuery<{ headsets: Headset[]; auditorium: Auditorium; audioDelay: number; }>(gql`
    query getHeadsetsFromUseService {
      auditorium {
        name
        status
        enterAt
        onboardingAt
        concertAt
        enterAt
        kiosk
        mdmVersion
        ktheaterVersion
        syncBroadcast
        debug
      }
      headsets {
        id
        status
        screen
        battery
        charging
        temperature
        connection
        appVer
      }
      audioDelay
    }
  `);
  const [auditorium, setAuditorium] = useState<Auditorium | null>(null);
  const [cachedHeadsets, setCachedHeadsets] = useState<{ [id: string]: Headset }>({});
  const [updatedHeadsets, setUpdatedHeadsets] = useState<{ [id: string]: Headset }>({});

  useEffect(() => {
    if (_cachedAuditorium) {
      setAuditorium(_cachedAuditorium);
    }

    if (_cachedHeadsets) {
      setCachedHeadsets(_cachedHeadsets.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {} as { [id: string]: Headset }));
    }
  }, [_cachedHeadsets, _cachedAuditorium]);

  const [auditoriumChanged, setAuditoriumChanged] = useState<Auditorium | null>(null);

  useSubscription<{ auditoriumChanged: Auditorium }>(gql`
    subscription subscribeAuditoriumChangedFromUseService {
      auditoriumChanged {
        name
        status
        enterAt
        onboardingAt
        concertAt
      }
    }
  `, {
    onData: ({ data: { data: { auditoriumChanged: _ } = {} } }) => {
      if (_) {
        setAuditoriumChanged(_);
      }
    }

  });

  useSubscription<{ headsetChanged: Headset }>(gql`
    subscription subscribeHeadsetChangedFromUseService {
      headsetChanged {
        id
        status
        screen
        battery
        charging
        temperature
        connection
        appVer
      }
    }
  `, {
    fetchPolicy: 'no-cache',
    onData: ({ data: { data: { headsetChanged } = {} } }) => {
      if (headsetChanged) {
        setUpdatedHeadsets((prev) => ({
          ...prev,
          [headsetChanged.id]: headsetChanged
        }));
      }
    }
  });

  return useMemo(() => ({
    auditorium: {
      ...auditorium,
      ...auditoriumChanged,
    },
    headsets: {
      ...cachedHeadsets,
      ...updatedHeadsets,
    },
    refetch,
    audioDelay: _audioDelay,
  }), [auditorium, auditoriumChanged, cachedHeadsets, updatedHeadsets, _audioDelay, refetch]);
};

export enum HeadsetStatus {
  Offline = 'Offline',
  Standby = 'Standby',
  Calibration = 'Calibration',
  Hub = 'Hub',
  Countdown = 'Countdown',
  Concert = 'Concert',
  Offboarding = 'Offboarding',
  TestAudio = 'TestAudio',
};

export enum ConcertStatus {
  Enter = 'Enter', // 입장시작: 극장내 배경음악 + 2D스크린에 로고 출력
  Onboarding = 'Onboarding', // 경험시작: 허브경험
  Concert = 'Concert', // 콘서트: 콘서경험
  Postshow = 'Postshow', // 포트스쇼: 2D 스크린에 비하인드 영상
  Standby = 'Standby', // 종료: 아무것도 안하는 상태 / 충전
};

export type Headset = {
  id: number; // 1 <= id 
  status: HeadsetStatus;
  screen: boolean; // headset screen on/off
  battery: number; // *MQTT* 0 <= battery <= 100
  charging: boolean; // *MQTT* 
  temperature: number; // *MQTT*  celsius
  connection: boolean; // *MQTT*
  appVer: string;
  ok?: boolean;
};

export type Auditorium = {
  name: string;
  status: ConcertStatus;
  onboardingAt?: string;
  concertAt?: string;
  enterAt?: string;
  kiosk: boolean;
  mdmVersion: number;
  ktheaterVersion: number;
  syncBroadcast: boolean;
  debug: boolean;
};
