import styled from "styled-components";

import { TabBodyItem, TabItem, Tabs } from "../components/Tabs";
import { Input } from "../components/Input";
import { InputGroup } from "../components/InputGroup";
import { Button } from "../components/Button";
import { useLocation } from "react-router-dom";
import { useService } from "../hooks/service";
import { useCallback, useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";

export const Settings = () => {
  const location = useLocation();
  const {
    auditorium,
    audioDelay: _audioDelay,
    headsets,
    refetch,
  } = useService();

  const [audioDelay, setAudioDelay] = useState<string>("");
  const [mdmVersion, setMdmVersion] = useState<string>("");
  const [ktheaterVersion, setKtheaterVersion] = useState<string>("");

  useEffect(() => {
    if (_audioDelay) {
      setAudioDelay(String(_audioDelay));
    }
    setMdmVersion(String(auditorium.mdmVersion));
    setKtheaterVersion(String(auditorium.ktheaterVersion));
  }, [auditorium]);

  const [updateAudioDelay] = useMutation<{ setAudioDelay: boolean }>(
    gql`
      mutation UpdateAudioDelay($delay: Int!) {
        setAudioDelay(delay: $delay)
      }
    `
  );

  const [updateMdmVersion] = useMutation<{ setMdmVersion: boolean }>(
    gql`
      mutation UpdateMdmVersion($version: Int!) {
        setMdmVersion(version: $version)
      }
    `
  );

  const [command] = useMutation<{ topic: string }>(
    gql`
      mutation SentMqttTopic($topic: String!) {
        sendMqttTopic(topic: $topic)
      }
    `
  );

  const [updateKtheaterVersion] = useMutation<{ setKtheaterVersion: boolean }>(
    gql`
      mutation UpdateKtheaterVersion($version: Int!) {
        setKtheaterVersion(version: $version)
      }
    `
  );

  const [updateKiosk] = useMutation<{ setKiosk: boolean }>(
    gql`
      mutation UpdateKiosk($enabled: Boolean!) {
        setKiosk(enabled: $enabled)
      }
    `
  );

  const [setSyncBroadcast] = useMutation<{ setSyncBroadcast: boolean }>(
    gql`
      mutation UpdateBroadcast($enabled: Boolean!) {
        setSyncBroadcast(enabled: $enabled)
      }
    `
  );

  const [audioDelayMessage, setAudioDelayMessage] = useState("");
  const handleSetAudioDelay = useCallback(async () => {
    setAudioDelayMessage("저장중...");
    const { data } = await updateAudioDelay({
      variables: {
        delay: Number(audioDelay),
      },
    });

    if (data?.setAudioDelay) {
      setAudioDelayMessage("✅ 저장되었습니다.");
    } else {
      setAudioDelayMessage("❌ 저장에 실패하였습니다.");
    }

    await refetch();
  }, [audioDelay]);

  const [mdmVersionMessage, setMdmVersionMessage] = useState("");
  const handleSetMdmVersion = useCallback(async () => {
    setMdmVersionMessage("저장중...");
    const { data } = await updateMdmVersion({
      variables: {
        version: Number(mdmVersion),
      },
    });

    if (data?.setMdmVersion) {
      setMdmVersionMessage("✅ 저장되었습니다.");
    } else {
      setMdmVersionMessage("❌ 저장에 실패하였습니다.");
    }

    await refetch();
  }, [mdmVersion]);

  const handleRebootAll = useCallback(async () => {
    await command({
      variables: {
        topic: "/client/all/command/reboot",
      },
    });
  }, []);

  const [ktheaterVersionMessage, setKtheaterVersionMessage] = useState("");
  const handleSetKtheaterVersion = useCallback(async () => {
    setKtheaterVersionMessage("저장중...");
    const { data } = await updateKtheaterVersion({
      variables: {
        version: Number(ktheaterVersion),
      },
    });

    if (data?.setKtheaterVersion) {
      setKtheaterVersionMessage("✅ 저장되었습니다.");
    } else {
      setKtheaterVersionMessage("❌ 저장에 실패하였습니다.");
    }

    await refetch();
  }, [ktheaterVersion]);

  const handleClickKioskMode = useCallback(async (enabled: boolean) => {
    await updateKiosk({
      variables: {
        enabled,
      },
    });

    await refetch();
  }, []);

  const handleClickBroadcast = useCallback(async (enabled: boolean) => {
    await setSyncBroadcast({
      variables: {
        enabled,
      },
    });

    await refetch();
  }, []);

  return (
    <Wrapper>
      {/* <Profile /> */}
      {/* <Spacer /> */}
      <Tabs
        tabs={
          <>
            <TabItem to="/settings/theater">극장</TabItem>
            <TabItem to="/settings/admin">관리자</TabItem>
          </>
        }
      >
        {location.pathname === "/settings/theater" && (
          <>
            <TabBodyItem>
              <label>
                <div>
                  <h1>Audio Delay</h1>
                  <div className="description">
                    헤드셋의 비디오와 극장오디오간의 싱크를 맞춥니다. (단위: ms)
                    {audioDelayMessage && (
                      <>
                        <br />
                        <div style={{ color: "white", marginTop: 5 }}>
                          {audioDelayMessage}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ flex: 1 }} />
                <div>
                  <InputGroup style={{ minWidth: 180, marginLeft: 10 }}>
                    <Input
                      maxLength={15}
                      placeholder="0"
                      style={{ width: 130 }}
                      value={audioDelay}
                      onChange={(e) => {
                        setAudioDelay(e.target.value);
                      }}
                    />
                    <Button
                      disabled={Number.isNaN(Number(audioDelay))}
                      onClick={() => handleSetAudioDelay()}
                    >
                      저장
                    </Button>
                  </InputGroup>
                </div>
              </label>
            </TabBodyItem>
          </>
        )}

        {location.pathname === "/settings/admin" && (
          <>
            <TabBodyItem>
              <div>
                <div>
                  <h1>Kiosk Mode</h1>
                  <div className="description">키오스크 모드를 토글합니다.</div>
                </div>
                <div style={{ flex: 1 }} />
                <div>
                  <InputGroup style={{ minWidth: 180, marginLeft: 10 }}>
                    <Button
                      disabled={auditorium.kiosk}
                      $selected={auditorium.kiosk}
                      onClick={() => handleClickKioskMode(true)}
                      style={{ flex: 1, marginRight: 5 }}
                    >
                      킴
                    </Button>
                    <Button
                      disabled={!auditorium.kiosk}
                      $selected={!auditorium.kiosk}
                      style={{ flex: 1 }}
                      onClick={() => handleClickKioskMode(false)}
                    >
                      끔
                    </Button>
                  </InputGroup>
                </div>
              </div>
            </TabBodyItem>

            <TabBodyItem>
              <div>
                <div>
                  <h1>Broadcast</h1>
                  <div className="description">
                    아래 설정을 실시간 싱크합니다.
                  </div>
                </div>
                <div style={{ flex: 1 }} />
                <div>
                  <InputGroup style={{ minWidth: 180, marginLeft: 10 }}>
                    <Button
                      disabled={auditorium.syncBroadcast}
                      $selected={auditorium.syncBroadcast}
                      onClick={() => handleClickBroadcast(true)}
                      style={{ flex: 1, marginRight: 5 }}
                    >
                      킴
                    </Button>
                    <Button
                      disabled={!auditorium.syncBroadcast}
                      $selected={!auditorium.syncBroadcast}
                      style={{ flex: 1 }}
                      onClick={() => handleClickBroadcast(false)}
                    >
                      끔
                    </Button>
                  </InputGroup>
                </div>
              </div>
            </TabBodyItem>

            <TabBodyItem>
              <label>
                <div>
                  <h1>MDM Version</h1>
                  <div className="description">
                    헤드셋의 MDM 앱을 해당 버전으로 교체합니다. (-1은 각 기기의
                    버전을 유지합니다.)
                    {mdmVersionMessage && (
                      <>
                        <br />
                        <div style={{ color: "white", marginTop: 5 }}>
                          {mdmVersionMessage}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ flex: 1 }} />
                <div>
                  <InputGroup style={{ minWidth: 180, marginLeft: 10 }}>
                    <Input
                      maxLength={15}
                      placeholder="-1"
                      style={{ width: 130 }}
                      value={mdmVersion}
                      onChange={(e) => setMdmVersion(e.target.value)}
                    />
                    <Button
                      disabled={Number.isNaN(Number(mdmVersion))}
                      onClick={() => handleSetMdmVersion()}
                    >
                      저장
                    </Button>
                  </InputGroup>
                </div>
              </label>
            </TabBodyItem>

            <TabBodyItem>
              <label>
                <div>
                  <h1>Ktheater Version</h1>
                  <div className="description">
                    헤드셋의 Ktheater 앱을 해당 버전으로 교체합니다. (-1은 각
                    기기의 버전을 유지합니다.)
                    {ktheaterVersionMessage && (
                      <>
                        <br />
                        <div style={{ color: "white", marginTop: 5 }}>
                          {ktheaterVersionMessage}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div style={{ flex: 1 }} />
                <div>
                  <InputGroup style={{ minWidth: 180, marginLeft: 10 }}>
                    <Input
                      maxLength={15}
                      placeholder="-1"
                      style={{ width: 130 }}
                      value={ktheaterVersion}
                      onChange={(e) => setKtheaterVersion(e.target.value)}
                    />
                    <Button
                      disabled={Number.isNaN(Number(ktheaterVersion))}
                      onClick={() => handleSetKtheaterVersion()}
                    >
                      저장
                    </Button>
                  </InputGroup>
                </div>
              </label>
            </TabBodyItem>
            <TabBodyItem>
              <label>
                <div>
                  <h1 style={{ color: "red" }}>REBOOT ALL DEVICES</h1>
                  <div className="description">
                    현재 접속된 모든 장비를 리붓합니다.
                  </div>
                </div>
                <div style={{ flex: 1 }} />
                <div>
                  <InputGroup style={{ minWidth: 180, marginLeft: 10 }}>
                    <></>
                    <Button onClick={() => handleRebootAll()}>모두 리붓</Button>
                  </InputGroup>
                </div>
              </label>
            </TabBodyItem>
          </>
        )}
      </Tabs>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 90px;
`;

const Spacer = styled.div`
  height: 10px;
`;
