import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { Portal } from "./Portal";
import { Button as _Button } from "./Button";

export type DialogOptions = {
  onConfirm: { (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): unknown };
  onDismiss?: { (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): unknown };
  title?: string;
  message?: ReactNode;
  confirmLabel?: string;
  dismissLabel?: string;
  confirmDelay?: number;
};

export type DialogActionRef = { open: (options: DialogOptions) => void };

export const Dialog: FC<{
  actionRef: { (ref: DialogActionRef): unknown };
}> = ({ actionRef }) => {
  const [options, setOptions] = useState<DialogOptions | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [confirmDelay, setConfirmDelay] = useState(0);

  useEffect(() => {
    actionRef({
      open: (_) => {
        setOptions(_);
        setOpen(true);
      },
    });
  }, [actionRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      setConfirmDelay((prev) => Math.max(0, prev - 1));
    }, 1_000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (open) {
      setConfirmDelay(options?.confirmDelay || 0);
    }
  }, [open, options]);

  const handleClickConfirm = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      options?.onConfirm(e);
      setOpen(false);
    },
    [options]
  );

  const handleClickDismiss = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      options?.onDismiss?.(e);
      setOpen(false);
    },
    [options]
  );

  return (
    <Portal>
      <Overlay $open={open} />

      <Wrapper $open={open}>
        <h1>{options?.title || "AmazeVR"}</h1>
        <Message>{options?.message}</Message>

        <div>
          <Button
            disabled={confirmDelay !== 0}
            style={{ outline: "2px red solid" }}
            onClick={handleClickConfirm}
          >
            {options?.confirmLabel || "확인"}{" "}
            {confirmDelay !== 0 && `(${confirmDelay})`}
          </Button>
          <Button onClick={handleClickDismiss}>
            {options?.dismissLabel || "취소"}
          </Button>
        </div>
      </Wrapper>
    </Portal>
  );
};

const Overlay = styled.div<{ $open: boolean }>`
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  transition: 200ms all;

  opacity: ${({ $open }) => ($open ? 1 : 0)};
  pointer-events: ${({ $open }) => ($open ? "auto" : "none")};
`;

const Wrapper = styled.div<{ $open: boolean }>`
  position: fixed;
  z-index: 100001;
  top: 50%;
  left: 50%;
  min-width: 350px;
  min-height: 200px;
  outline: 1px #333 solid;
  box-shadow: 0 0 20px #00000080;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 20px 20px;
  background: linear-gradient(0, #000000 0%, #161718ee 100%);
  transition: 200ms all;
  transform-origin: 50% 40%;
  pointer-events: ${({ $open }) => ($open ? "auto" : "none")};

  ${({ $open }) =>
    $open
      ? `
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        `
      : `
          transform: translate(-50%, -50%) scale(1.03);
          opacity: 0;
    `};
`;

const Button = styled(_Button)`
  min-width: 100px;
  height: 40px;
  margin: 15px;
`;

const Message = styled.div`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 20px;
`;
