import styled from "styled-components";

export const Button = styled.button<{ $selected?: boolean }>`
  height: 23px;
  padding: 0 10px;
  background-color: #3a3b3c;
  color: #e4e6eb;
  border: none;
  border-radius: 4px;
  transition: all 200ms;

  ${({ $selected }) =>
    $selected
      ? `
        background-color: #2a2b2c;
        font-weight: bold;
          border: 3px #228822 solid;
          background: #224422;
        `
      : `
          outline: 1px solid #3a3b3c;

          :disabled {
            background-color: #2a2b2c;
            color: #444;
            cursor: default;
            outline: 1px solid #2a2b2c;
          }
        
          :not(:disabled) {
            &:hover {
              background-color: #666768;
            }
        
            &:focus {
              outline: 1px solid #7a7a7b;
              transition: none;
            }
        
            &:active {
              background-color: #505151;
              transition: none;
            }
          }
      `}
`;
